import { Component, OnInit } from '@angular/core';
import { MenuController, NavController, Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { MenuList } from 'src/environments/lists';
import { TaskService } from './services/task.service';
import { VariablesService } from './services/variables.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  menuList = MenuList;
  selectedRow: number;
  info = environment.info;
  constructor(
    private navCtrl: NavController,
    public taskService: TaskService,
    public vars: VariablesService,
    private menuCtrl: MenuController,
    public plt: Platform
  ) {}

  ngOnInit()  {
    if (!this.plt.is('android')) { this.vars.toggleMenu(); }
  }
  
  irA(ruta, id) {
    this.selectedRow = id;
    this.navCtrl.navigateRoot(ruta.redirect);
    this.menuCtrl.close();

  }

  logout() {
    environment.info = null;
    environment.info02 = '';
    environment.info03 = '';
    environment.info04 = '';
    this.navCtrl.navigateRoot('login');
    this.vars.loadHome = false;
    this.vars.showMenu = false;
    this.menuCtrl.enable(false);
    this.menuCtrl.close();
  }
}
