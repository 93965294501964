import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full'},
  { path: 'login', loadChildren: () => import('../app/pages/login/login.module').then( m => m.LoginPageModule)},
  { path: 'home', loadChildren: () => import('../app/pages/home/home.module').then( m => m.HomePageModule), canActivate: [AuthGuard]},
  {
    path: 'cpanel',
    loadChildren: () => import('../app/pages/cpanel/cpanel.module').then( m => m.CpanelPageModule), canActivate: [AuthGuard]
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
