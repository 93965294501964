import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { map, catchError, finalize } from 'rxjs/operators';
import { LoadingController } from '@ionic/angular';
import { TaskService } from '../services/task.service';
import { VariablesService } from '../services/variables.service';
@Injectable({ providedIn: 'root' })
export class InterceptorService implements HttpInterceptor {
  coords: any = {};
  loaderToShow: any;
  constructor(
    private loadCtrl: LoadingController,
    private tasService: TaskService,
    private vars: VariablesService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers: HttpHeaders;
    if (this.vars.isLogin) {
      headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Info00', String(environment.info00))
      .set('Info01', String(environment.info01));
    }/*  else if (this.vars.createUserAccount){
      headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Info00', String(environment.info00))
      .set('Info01', String(environment.info01));

    } */ else {
      headers = new HttpHeaders()
      .set('Authorization', String(environment.info.token))
      .set('Info00', String(environment.info00))
      .set('Info01', String(environment.info01))
      .set('Info02', String(environment.info02))
      .set('Info03', String(environment.info03))
      .set('Info04', '');
    }

    const reqClone = req.clone({
      headers
    });
    return next.handle(reqClone).pipe(
    map((event: HttpEvent<any>) => {
      return event;
    }),
    catchError((error: HttpErrorResponse) => {
      // console.log('errorInterceptor ', error);
      return throwError(error);
    }), finalize(() => {
      this.loadCtrl.getTop().then(load => load ? this.loadCtrl.dismiss() : null);
    }));
  }
}


