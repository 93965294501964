import { Injectable } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Param } from '../task/interface';

@Injectable({
  providedIn: 'root'
})
export class VariablesService {
  isLogin = true;
  showMenu = false;
  loadHome = false;
  diasCortos = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'];
  mesesCortos = ['Ene', 'Feb', 'Mzo', 'Abr', 'May', 'Jun', 'Jul', 'Agt', 'Sept', 'Oct', 'Nov', 'Dic'];
  diasLargos = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
  mesesLargos = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
                 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

  modal = {
    key: 1,
    plan: 2
  };

  listDataType: Param = {}; // 1
  listLanguaje: Param = {}; // 2
  listCountry: Param = {}; // 3
  listMoney: Param = {}; // 6
  listTypeAttributes: Param = {}; // 49
  listSystemType: Param = {}; // 50
  
  constructor(
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private alertCtrl: AlertController
  ) { }

  isEmpty(value: any) {
    if (value === undefined || value === null || value.length <= 0 || value === '' || value <= 0) {
      return true;
    } else { return false; }
  }

  isEmptyDoc(value: any) {
    if (value === undefined || value === null || value.length <= 0 || value === '') {
      return true;
    } else { return false; }
  }

  async toastGlobal(info: string, positions: any, durations: number) {
    const toast = await this.toastCtrl.create({
      message: info,
      position: positions,
      duration: durations,
      mode: 'ios',
      // color: colors
    });
    toast.present();
  }

  validarLogin(): Promise<boolean> {
    if (this.isEmpty(environment.info)) {
      this.navCtrl.navigateRoot('login');
      return Promise.resolve(false);
    }
    return new Promise<boolean>(resolve => {
      if (this.isEmpty(environment.info)) {
        this.navCtrl.navigateRoot('login');
        resolve(false);
      } else {
        resolve(true);
      }
    });
  }

  toggleMenu() {
    const splitPane = document.querySelector('ion-split-pane');
    const windowWidth = window.innerWidth;
    const splitPaneShownAt = 650; // 992 // 650
    const when = `(min-width: ${splitPaneShownAt}px)`;
    if (windowWidth >= splitPaneShownAt) {
      // split pane view is visible
      const open = splitPane.when === when;
      splitPane.when = open ? false : when;
    } else {
      // split pane view is not visible
      // toggle menu open
      const menu = splitPane.querySelector('ion-menu');
      return menu.open();
    }
  }

  async alertGlobal(mensaje: string, header?) {
    const alert = await this.alertCtrl.create({
      header: header === undefined || header ? header : 'Mensaje',
      message: mensaje,
      cssClass: this.isEmpty(header) ? 'alert-migration' : '',
      mode: 'ios',
      buttons: [{text: 'OK', cssClass: 'alertAcept'}]
    });
    alert.present();
  }

  async mensajeSubscribeError(error: any, page?) {
    // const findError = StatusError.find(f => f.code === error.status);
    this.alertGlobal(error.error + '</br>( Estado: ' + error.status + ')', 'Alerta! ' + page); // || findError.text
  }

  alertGlbBool(head: string, mess: string, subHead?: any, btnAcept?: string, btnCancel?: string, cerrar?: boolean): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.alertCtrl.create({
        subHeader: subHead ? '' : subHead,
        header: head,
        message: mess,
        backdropDismiss: cerrar,
        cssClass: subHead ? 'alert-migration' : '',
        mode: 'ios',
        buttons: [
          {
            text: btnCancel === undefined ? 'Cancelar' : btnCancel,
            cssClass: 'alertCancel',
            handler: () => reject(false)
          },
          {
            text: btnAcept === undefined ? 'Aceptar' : btnAcept,
            cssClass: 'alertAcept',
            handler: () => {
              resolve(true);
            }
          }
        ]
      })
      .then(alert => {
        alert.present();
      });
    });
  }
}
