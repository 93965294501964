import { Injectable } from '@angular/core';
import { CanActivate, CanLoad } from '@angular/router';
import { Observable } from 'rxjs';
import { VariablesService } from '../services/variables.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(
    private vars: VariablesService) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.vars.validarLogin();
  }

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    return this.vars.validarLogin();
  }

}
