const fecha = new Date();

export const Slider = [
  { title: 'Tu negocio en línea', desc: 'Tus operaciones comerciales en cualquier parte del mundo', img: '../../assets/slides/Slider7.png'},
  { title: 'Tu negocio en tu móvil', desc: 'Vende tus productos en línea', img: '../../assets/slides/Slider8.png'},
  { title: 'Facturación digital', desc: 'Genera e imprime tus facturas', img: '../../assets/slides/Slide4.png'},
  { title: 'Tienda abierta 24/7', desc: 'Registra tus operaciones en cualquier momento', img: '../../assets/slides/Slide5.png'}
];

export const MenuList = [
  { redirect: 'home', text: 'Inicio', icon: 'bar-chart-outline', id: 1 },
  { redirect: 'cpanel', text: 'Panel de control', icon: 'cog', id: 2},
 ];

export const StatusError = [
  {code: 0, text: 'Error comunicacion con el servidor.'},
  {code: 302, text: 'La cuenta de esta compañía no esta activa. Proceda a su activación.'},
  {code: 400, text: 'Solicitud incorrecta, la solicitud contiene sintaxis errónea.'},
  {code: 401, text: 'No autorizado </br> usuario y/o Contraseña no son correctos.'},
  {code: 403, text: 'Prohibido </br> Usuario y/o Contraseña no son correctos.'},
  {code: 404, text: 'No se encontró el recurso solicitado.'},
  {code: 405, text: 'Método no permitido.'},
  {code: 406, text: 'Respuesta no aceptable.'},
  {code: 408, text: 'El servidor se agotó esperando el resto de la petición del navegador.'},
  {code: 415, text: 'El formato de los datos solicitados no está soportado por el servidor.'},
  {code: 500, text: 'Hubo un error en el servidor y la solicitud no pudo ser completada.'},
  {code: 502, text: 'Respuesta inválida.'},
  {code: 503, text: 'El servidor no está disponible para manejar esta solicitud en este momento.'},
  {code: 504, text: 'El servidor, actuando como una puerta de enlace, se ha agotado esperando a que otro servidor responda.'},
  {code: 505, text: 'Versión HTTP no soportada.'},
  {code: 521, text: 'El servidor web está caído.'},

];
