// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // url: 'http://192.168.1.5/app-cronos-1.0.0/facturin/service/',
  url: 'https://devs.jabes.app:8443/app-cronos-1.0.0/facturin/service/',
  info00: 'A439Ade2540A4bd998a58445388f0898', // llave
  info01: '', // dea04d477bccdd51
  info02: '', // longitude
  info03: '', // latitude
  info04: '', // altitude
  info: null
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
