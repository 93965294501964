import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingController, NavController, ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { CPanel, JabesQuery, PanelPlans, Param, UserInfo } from '../task/interface';
import { VariablesService } from './variables.service';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  url = environment.url;
  info: any = {};

  constructor(
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController,
    private http: HttpClient,
    private navCtrl: NavController,
    private vars: VariablesService
  ) { }

  async login(username: string, password: string) {
    const loading = await this.loadingCtrl.create({
      message: 'Validando información...'
    });
    loading.present();
    const postData = 'username=' + username + '&password=' + password + '&deviceId=' + environment.info01 + '&Info00=' + environment.info00;
    return this.http.post(`${this.url}authentication/`, postData)
    .subscribe((res: UserInfo) => {
      this.info = res;
      this.vars.toastGlobal('Bienvenido, ' + res.fullName, 'bottom', 2000);
      environment.info = res;
      console.log('UserInfo ', res);
      this.obtenerParametros();
      loading.dismiss();
      this.navCtrl.navigateRoot('home', { animated: true });
    }, (error: HttpErrorResponse) => {
      loading.dismiss();
      console.log('error --> ', error);
      this.vars.mensajeSubscribeError(error, 'Login')
      /* if (error.status === 302) {
        const infoBusiness: InfoBusinessAccount = {};
        infoBusiness.email = username;
        this.valService.alertGlbBool('¿Recuperar?', error.error, '', 'Ir', 'Cancelar', false).then((ok) => {
          if (ok) {
            this.navCtrl.navigateRoot('register');
            this.vars.sendCodeEmail(infoBusiness, this.vars.pageValid.recovery);
          }
        }, err => {});
      } else {
        // this.valService.mensajeSubscribeError(error, 'Login');
        console.log(error, 'Login');
      } */
    });
  }

  async obtenerParametros() {
    this.vars.isLogin = false;
    const loading = await this.loadingCtrl.create({
      message: 'Obteniendo parámetros, espere por favor...',
    });
    loading.present();
    this.getPanelParams().subscribe((dataParameter: Param[]) => {
      for (const list of dataParameter) {
        switch (list.code) {
          case 1: this.vars.listDataType = list; break; // 1
          case 2: this.vars.listLanguaje = list; break; // 1
          case 3: this.vars.listCountry = list; break; // 1
          case 6: this.vars.listMoney = list; break; // 1
          case 49: this.vars.listTypeAttributes = list; break; // 1
          case 50: this.vars.listSystemType = list; break; // 1
          default: console.log('no encontrado'); break;
        }
      }
      loading.dismiss();
    }, (err: any) => {
      console.log('Error....', err);
      loading.dismiss();
    });
  }

  /* Services control panel */
  getPanelParams() {
    const path = `${this.url}system/panel/params`;
    return this.http.get<Param[]>(path);
  }

  filterPanel(jabesQ: JabesQuery) {
    const path = `${this.url}system/panel/filter`;
    return this.http.post<CPanel[]>(path, jabesQ);
  }

  findPanel(id: number) {
    const path = `${this.url}system/panel/${id}`;
    return this.http.get<CPanel>(path);
  }

  savePanel(cpanel: CPanel) {
    const path = `${this.url}system/panel/save`;
    return this.http.post<CPanel>(path, cpanel);
  }

  savePanelPlan(panelPlans: PanelPlans) {
    const path = `${this.url}system/panel/plan/save`;
    return this.http.post<PanelPlans>(path, panelPlans);
  }
  /* ******************************************** */


}
